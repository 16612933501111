import React from 'react'
import { graphql } from 'gatsby'
import { motion } from "framer-motion"
import { fadeSlow, revealInOut } from "../helpers/transitionHelper"
import BlockBody from '../components/BlockBody';
import {Helmet} from "react-helmet";

import urlFor from '../helpers/imageBuilder'

export const query = graphql`
  query EventPostTemplateQuery($id: String!) {
    sanitySiteSettings {
      title
    }
    post: sanityEvents(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      _rawBody
      _rawImage
      date
    }
  }
`

const EventPostTemplate = props => {
  const { data } = props
  const post = data && data.post
  return (
    <motion.section
      initial="initial"
      animate="enter"
      exit="exit"
    >    
      <Helmet>
        <title>{data.post.title} - {data.sanitySiteSettings.title}</title>
      </Helmet>
      <motion.div variants={fadeSlow}>
         <motion.div variants={{
          enter: { transition: { 
            staggerChildren: .1,
            delayChildren: props.childAnimationDelay
          }}
        }}>
          <div className="post-header">
            <div>
              <motion.h1 variants={revealInOut} className="post-title">{data.post.title}</motion.h1>
              <motion.div variants={revealInOut} className="post-meta">
                {/* <p>by {post.authors.name}</p> */}
                <p>{post.date}</p>
              </motion.div>
            </div>

            <motion.img variants={revealInOut} className="post-image" src={urlFor(post._rawImage).width(600).url()} alt={post.title}/>
          </div>
        
          <motion.div variants={revealInOut} className="post-body">
            <BlockBody blocks={post._rawBody}/>
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}

export default EventPostTemplate